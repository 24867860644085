// constants
import { DOCUMENT_ELEMENT, KEY_CODES, SELECTORS } from '@/scripts/constants';

// other
import { toggleScrollbar } from '../../scripts/utils/toggleScrollbar';

export function wishList() {
  const menuButton = document.querySelector('[data-role="wish-button"]');
  let timeout = null;

  const toggle = (allow) => {
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.active, allow);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      toggleScrollbar(allow);
      DOCUMENT_ELEMENT.classList.toggle(SELECTORS.has_modal, allow);
    }, allow ? 0 : 750);
  };

  const isActive = () => DOCUMENT_ELEMENT.classList.contains(SELECTORS.active);

  const setupEvents = () => {
    menuButton.addEventListener('click', (e) => {
      e.preventDefault();
      if (DOCUMENT_ELEMENT.classList.contains(SELECTORS.menu_opened)) {
        DOCUMENT_ELEMENT.classList.remove('menu-opened');
      }
      toggle(!isActive());
    });
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === KEY_CODES.esc && isActive()) {
        toggle(false);
      }
    });
  };

  if (menuButton) {
    setupEvents();
  }

  // const wishlistItem = document.querySelectorAll('[data-wishlist-item]');
  // if (wishlistItem) {
  //   wishlistItem.forEach((item) => {
  //     const wishlistItemRemove = item.querySelector('[data-wishlist-item-del]');
  //     wishlistItemRemove.addEventListener('click', () => {
  //       item.remove();
  //     });
  //   });
  // }
}
