// constants
import { DOCUMENT_ELEMENT, KEY_CODES, SELECTORS } from '@/scripts/constants';

// other
import { toggleScrollbar } from '@/scripts/utils/toggleScrollbar';

// assets
import './hamburgerMenu.scss';

export function menuToggle() {
  const sidebar = document.querySelector('.sidebar');
  const blocksToHide = document.querySelectorAll('.block-hiding');
  const menuButton = document.querySelector('[data-role="menu-button"]');
  const $linksWrapper = $('[data-menu-links]');
  const $links = $linksWrapper.find('[data-link]');
  const $images = $linksWrapper.find('[data-image]');
  const $imagesWrapper = $linksWrapper.find('[data-images]');

  $imagesWrapper.append($images.removeClass(SELECTORS.hidden));

  const activeIndex = Array.from($links).findIndex(el => $(el).hasClass(SELECTORS.active));

  $links.each((i, link) => {
    const $link = $(link);
    const $image = $($images[i]);
    $link.on('mouseenter', () => {
      $($images[activeIndex]).removeClass(SELECTORS.active);
      $image.addClass(SELECTORS.active);
    });
    $link.on('mouseleave', () => {
      $image.removeClass(SELECTORS.active);
      $($images[activeIndex]).addClass(SELECTORS.active);
    });
  });
  // let menuContainer = document.querySelector('[data-role="menu-container"]')
  let timeout = null;
  let imageTimeout = null;

  const toggle = (allow) => {
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.menu_opened, allow);
    clearTimeout(timeout);
    clearTimeout(imageTimeout);
    timeout = setTimeout(() => {
      toggleScrollbar(allow);
      DOCUMENT_ELEMENT.classList.toggle(SELECTORS.has_modal, allow);
    }, allow ? 0 : 750);

    imageTimeout = setTimeout(() => {
      $($images[activeIndex]).toggleClass(SELECTORS.active, allow);
    }, allow ? 750 : 0);
  };

  const isActive = () => DOCUMENT_ELEMENT.classList.contains(SELECTORS.menu_opened);

  const setupEvents = () => {
    menuButton.addEventListener('click', (e) => {
      e.preventDefault();
      toggle(!isActive());
      blocksToHide.forEach(block => block.classList.toggle('hideBlock'));
      sidebar.classList.toggle('sidebar--small');
    });

    document.addEventListener('keyup', (e) => {
      if (e.keyCode === KEY_CODES.esc && isActive()) {
        toggle(false);
        blocksToHide.forEach(block => block.classList.toggle('hideBlock'));
        sidebar.classList.toggle('sidebar--small');
      }
    });
  };

  if (menuButton) {
    setupEvents();
  }
}
