const logos = document.querySelectorAll('.main-logo');

const animatePath = (element, params = {}) => {
  const { stroke = '#d1b769' } = params;
  const length = element.getTotalLength();

  const keyframes = [
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: `${length}`,
      fillOpacity: 0,
      stroke,
      offset: 0,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: `${length / 2.5}`,
      fillOpacity: 0,
      stroke,
      offset: 0.3,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: 0,
      fillOpacity: 1,
      stroke: 'transparent',
      offset: 0.45,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: 0,
      fillOpacity: 1,
      stroke: 'transparent',
      offset: 0.6,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: `${length / 2.5}`,
      fillOpacity: 0,
      stroke,
      offset: 0.75,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: `${length}`,
      fillOpacity: 0,
      stroke,
      offset: 0.95,
    },
    {
      strokeDasharray: `${length}`,
      strokeDashoffset: `${length}`,
      fillOpacity: 0,
      stroke: 'transparent',
      offset: 1,
    },
  ];

  const options = {
    duration: 11000,
    iterations: Infinity,
  };

  element.animate(keyframes, options);
};

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.querySelectorAll('path').forEach((item) => {
        animatePath(item, entry.target.dataset);
      });
    }
  });
});

logos.forEach(logo => observer.observe(logo));
