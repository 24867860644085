import './animateDecor.scss';

const decorDots = document.querySelectorAll('.decor-element');

const animateDots = (decor) => {
  const paths = decor.querySelectorAll('path');
  paths.forEach((element, index) => {
    const randomNumber = Math.floor(Math.random() * paths.length);

    const keyframesOdd = [
      {
        visibility: 'hidden',
        transform: 'rotate(0deg) translateY(-1%)',
        opacity: 0,
      },
      {
        visibility: 'hidden',
        transform: 'rotate(0deg) translateY(-1%)',
        opacity: 0,
        offset: 0.49,
      },
      {
        visibility: 'visible',
        transform: 'rotate(0deg) translateY(-1%)',
        opacity: 0.5,
        offset: 0.5,
      },
      {
        visibility: 'visible',
        transform: `rotate(-${randomNumber}deg) translateY(50%)`,
        opacity: 0.9,
        offset: 0.75,
      },
      {
        visibility: 'visible',
        transform: `rotate(-${randomNumber * 2}deg) translateY(100%)`,
        opacity: 0.5,
        offset: 0.99,
      },
      {
        visibility: 'hidden',
        transform: `rotate(-${randomNumber * 2}deg) translateY(101%)`,
        opacity: 0,
        offset: 1,
      },
    ];
    const keyframesEven = [
      {
        visibility: 'hidden',
        transform: `rotate(-${randomNumber}deg) translateY(-1%)`,
        opacity: 0,
      },
      {
        visibility: 'visible',
        transform: `rotate(-${randomNumber}deg) translateY(-1%)`,
        opacity: 0.5,
        offset: 0.01,
      },
      {
        visibility: 'visible',
        transform: `rotate(-${randomNumber * 2}deg) translateY(50%)`,
        opacity: 0.9,
        offset: 0.5,
      },
      {
        visibility: 'visible',
        transform: `rotate(-${randomNumber * 2}deg) translateY(100%)`,
        opacity: 0.5,
        offset: 0.99,
      },
      {
        visibility: 'hidden',
        transform: `rotate(-${randomNumber * 2}deg) translateY(101%)`,
        opacity: 0,
        offset: 1,
      },
    ];

    const options = {
      duration: 90000,
      iterations: Infinity,
    };

    if (index % 2 === 0) {
      element.animate(keyframesEven, options);
    } else {
      element.animate(keyframesOdd, options);
    }
  });
};

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const targetDecor = entry.target;
      animateDots(targetDecor);
    }
  });
});

decorDots.forEach(image => observer.observe(image));
