// node-modules
import PubSub from 'pubsub-js';

// constants
import { SELECTORS } from '@/scripts/constants';

// assets
import './preloader.scss';

export const PRELOADER_EVENTS = {
  startLoading: 'PRELOADER:START',
  finishLoading: 'PRELOADER:END',
};

const PRELOADER_KEY = 'have-seen-preloader';

(function initPreloader() {
  const preloader = document.querySelector('[data-role="preloader"]');
  const key = sessionStorage.getItem(PRELOADER_KEY);
  if (!key) {
    sessionStorage.setItem(PRELOADER_KEY, 'seen');
  }

  if (!preloader) {
    document.addEventListener('DOMContentLoaded', () => {
      PubSub.publish(PRELOADER_EVENTS.finishLoading);
    });
  } else {
    const load = () => {
      Array.from(preloader.children).forEach((child) => {
        child.addEventListener('transitionend', (e) => {
          e.preventDefault();
          e.stopPropagation();
        });
      });
      setTimeout(() => {
        preloader.classList.add(SELECTORS.hidden);
        PubSub.publish(PRELOADER_EVENTS.finishLoading);
      }, key ? 0 : 1500);
    };

    document.addEventListener('DOMContentLoaded', () => {
      preloader.classList.add(SELECTORS.loaded);
      const coverImg = document.querySelector('[data-animation-cover] img');
      if (!coverImg) {
        load();
        return;
      }
      if (coverImg.complete) {
        load();
      } else {
        coverImg.addEventListener('load', load);
      }
    });
  }
}());
