// assets
import './moveImage.scss';
import { TweenMax } from 'gsap/TweenMax';
import { SELECTORS } from '../../scripts/constants';
import { isMobile } from '../../scripts/utils/isMobile';

const backgrounds = [];

const getColor = (img, index) => {
  if (!img.complete) {
    backgrounds[index] = null;
    img.addEventListener('load', () => {
      getColor(img, index);
    });
    return;
  }
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const blockSize = 5;
  let i = -4;
  const rgb = { r: 0, g: 0, b: 0 };
  const defaultRGB = { r: 0, g: 0, b: 0 };
  let count = 0;
  let data;
  // eslint-disable-next-line no-multi-assign
  const height = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
  // eslint-disable-next-line no-multi-assign
  const width = canvas.width = img.naturalWidth || img.offsetWidth || img.width;

  // ctx.drawImage(img, 0, 0);

  try {
    data = ctx.getImageData(0, 0, width, height);
  } catch (e) {
    console.warn(e);
    Object.assign(rgb, defaultRGB);
  }

  const { length } = data.data;

  // eslint-disable-next-line no-cond-assign
  while ((i += blockSize * 4) < length) {
    // eslint-disable-next-line no-plusplus
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  // eslint-disable-next-line no-bitwise
  rgb.r = ~~(rgb.r / count);
  // eslint-disable-next-line no-bitwise
  rgb.g = ~~(rgb.g / count);
  // eslint-disable-next-line no-bitwise
  rgb.b = ~~(rgb.b / count);

  const { r, g, b } = rgb;
  backgrounds[index] = `rgba(${r}, ${g}, ${b}, .5)`;
};

export function moveImage() {
  if (isMobile()) {
    return;
  }

  const wrapper = document.querySelector('[data-move-image]');
  if (!wrapper) {
    return;
  }


  const $wrapper = $(wrapper);
  const $container = $wrapper.find('[data-move-container]');
  const $links = $container.find('[data-link]');
  const $images = $container.find('[data-image]');
  const $moveBlock = $container.find('[data-move-block]');
  $moveBlock.append($images.removeClass(SELECTORS.hidden));

  let offset = $container.offset();
  let height = $container.height();

  const move = (e) => {
    const { pageX, pageY } = e;
    const { left, top } = offset;
    const clientX = pageX - left;
    const clientY = pageY - top;
    TweenMax.to($moveBlock, 0.8, {
      x: clientX,
      y: (1 - 2 * (clientY / height)) * 100,
    });
  };

  $links.each((i, link) => {
    const $link = $(link);
    const $image = $($images[i]);

    getColor($image.find('img')[0], i);

    $link.on('mouseenter', (e) => {
      move(e);
      $image.addClass(SELECTORS.active);
      // wrapper.style.setProperty('--background-color', backgrounds[i]);
    });
    $link.on('mouseleave', () => {
      $image.removeClass(SELECTORS.active);
      // wrapper.style.setProperty('--background-color', 'rgba(0, 0, 0, 0)');
    });
  });

  $container.on('mousemove', move);

  $(window).on('resize', () => {
    offset = $container.offset();
    height = $container.height();
  });
}
