import 'jquery-zoom';
import { isMobile} from '../../scripts/utils/isMobile';

$(document).ready(() => {
  if (isMobile()) {
    return;
  }
  $('[data-slider-zoom]').each((i, slide) => {
    const $slide = $(slide);
    $slide.zoom({
      url: $slide.data('url'),
      on: 'click',
      touch: 'true',
      magnify: '1',
      duration: '300',
      onZoomIn: () => {
        $('[data-plus-cursor]').addClass('is-active');
      },
      onZoomOut: () => {
        $('[data-plus-cursor]').removeClass('is-active');
      },
    });
  });
});
