// node-modules
import { Linear } from 'gsap/all';
import ScrollMagic from 'scrollmagic';
import { controller } from '../../scripts/utils/scrollmagic';
import 'animation.gsap';
import 'debug.addIndicators';


(function initParallax() {
  document.querySelectorAll('[data-parallax]').forEach((el) => {
    new ScrollMagic.Scene({
      triggerElement: el,
      duration: '100%',
      triggerHook: 'onEnter',
      offset: +el.dataset.offset || 100,
    })
      .setTween(el, { y: '80', ease: Linear.easeNone })
      .addTo(controller);
  });
}());
