import { TweenMax } from 'gsap/TweenMax';
// import { SELECTORS } from '../../scripts/constants';

import './plusCursor.scss';

export function plusCursor() {
  const $plusCursor = $('[data-plus-cursor]');
  const $container = $('[data-role="slider-product"]');

  const move = (e) => {
    TweenMax.to($plusCursor, 0.8, {
      x: e.clientX - 16,
      y: e.clientY - 16,
    });
  };

  $container.on('mousemove', (e) => {
    move(e);
  });
  const sliderButton = $('[data-slider-button]');
  sliderButton.each((i, button) => {
    const $button = $(button);
    $button.on('mouseenter', () => {
      $plusCursor.addClass('invisible');
    });

    $button.on('mouseleave', () => {
      $plusCursor.removeClass('invisible');
    });
  });
}
