import { TweenLite } from 'gsap/TweenLite';
import _debounce from 'lodash/debounce';

export class AnimatedListPointer {
  constructor(list) {
    this.list = list;
    this.hover = this.list.dataset.hoverItem;
    this.listItems = Array.from(this.list.querySelectorAll('[data-role*="list-item"]'));
    this.pointer = this.list.querySelector('[data-role="forward-pointer"]');
    this.duration = document.documentElement.dataset.duration;
    this.widtn = this.pointer.offsetWidth;
    this.height = this.pointer.offsetHeight;
    this.init();
  }

  setStyle(target) {
    const {
      offsetLeft, offsetWidth, offsetHeight, offsetTop,
    } = target;
    const x = offsetLeft + offsetWidth / 2 - this.widtn / 2;
    const y = offsetTop + offsetHeight / 2 - this.height / 2;
    TweenLite.to(this.pointer, this.duration, { x, y });
  }

  forwardHovered(e) {
    const { target } = e;
    return this.setStyle(target);
  }

  backToActive() {
    const target = this.list.querySelector('.is-active');
    if (!target) return false;
    return this.setStyle(target);
  }

  init() {
    if (this.hover) {
      this.listItems.forEach((item) => {
        item.addEventListener('mouseenter', this.forwardHovered.bind(this));
        item.addEventListener('mouseleave', this.backToActive.bind(this));
      });
    }

    this.backToActive();
    this.list.addEventListener('updateLine', () => {
      this.backToActive();
    });

    window.addEventListener('resize', _debounce(() => this.backToActive(), 50));
  }
}
