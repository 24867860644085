// node-modules
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';
import { TweenLite, Power1 } from 'gsap/TweenMax';
import { ScrollToPlugin } from 'gsap/all';

// polyfills
import 'dom4';

// default components
import '@/components/lazy-load';
import '@/components/preloader';
import '@/components/footer';
import '@/components/scrollToTop';
import '@/components/animations';
import '@/components/button';
import '@/components/sidebar';
import '@/components/breadcrumbs';
import '@/components/scrollMagic';
import '@/components/imageZoom';
import '@/components/animateLogo';
import '@/components/animateDecor';

// functional components
import { sayHello } from '@/scripts/utils/sayHello';
import { Form } from '@/components/Form';
import { AnimatedListLine } from '@/components/AnimatedListLine';
import { moveImage } from '@/components/moveImage';
import { AnimatedListPointer } from '@/components/AnimatedListPointer';
import { menuToggle } from '@/components/hamburgerMenu';
import { sliderMoodboard, sliderProduct } from '@/components/swiperSlider';
import { customSelect } from '@/components/customSelect';
import { initGoogleMaps } from '@/components/googleMaps';
import { wishList } from '@/components/wishList';
import { productCard } from '@/components/productCard';
import { Dropdown } from '@/components/Dropdown';
import { plusCursor } from '@/components/plusCursor';

// assets
import 'styles/main.scss';
import 'fonts';


// eslint-disable-next-line
const plugins = [ScrollToPlugin];

TweenLite.defaultEase = Power1.easeOut;


document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
  svg4everybody();
  sayHello();
  moveImage();
  // init menu
  menuToggle();
  wishList();
  productCard();
  plusCursor();
  // init sliders
  document.querySelectorAll('[data-role="slider-moodboard"]')
    .forEach(container => sliderMoodboard(container));
  document.querySelectorAll('[data-role="slider-product"]')
    .forEach(container => sliderProduct(container));
  // init list-decoration
  document.querySelectorAll('[data-role*="animated-list-line"]')
    .forEach(list => new AnimatedListLine(list));
  document.querySelectorAll('[data-role*="animated-list-pointer"]')
    .forEach(list => new AnimatedListPointer(list));
  // init form
  document.querySelectorAll('[data-role="contact-form"]')
    .forEach(element => new Form(element));
  // selects
  document.querySelectorAll('[data-custom-select]')
    .forEach(el => customSelect(el));
  // init google maps
  document.querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el));
  // init dropdown
  document.querySelectorAll('[data-role*="dropdown-container"]')
    .forEach(element => new Dropdown({ element }));
});
