// node-modules
import ScrollMagic from 'scrollmagic';
import { TweenLite } from 'gsap/TweenLite';

// constants
import { ROOT_CLASS, SELECTORS } from '../../scripts/constants';

// other
import { controller } from '../../scripts/utils/scrollmagic';

(function scrollToTop() {
  const scrollButton = document.createElement('div');
  const innerArrow = document.createElement('span');
  const content = document.querySelector('[data-role="content"]');
  if (!content || content.classList.contains('no-scroll-top')) return false;

  scrollButton.className = `${ROOT_CLASS} button__up`;
  innerArrow.className = 'button__up-decor';
  scrollButton.dataset.role = 'scroll-to-top';
  document.body.appendChild(scrollButton);
  scrollButton.appendChild(innerArrow);

  scrollButton.addEventListener('click', () => {
    TweenLite.to(window, 0.8, { scrollTo: { y: 0, autoKill: false } });
  });

  return new ScrollMagic.Scene({
    triggerElement: content,
    triggerHook: 'onLeave',
    offset: 400,
  })
    .setClassToggle(scrollButton, SELECTORS.active)
    // .addIndicators({ name: 'Scroll to top' })
    .addTo(controller);
}());

document.addEventListener('DOMContentLoaded', () => {
  const aboutSection = document.querySelectorAll('.observe-to-up');
  const btn = document.querySelector('.button__up');

  const options = {
    rootMargin: '800px',
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        btn.classList.add('button__up--show');
      }

      if (!entry.isIntersecting) {
        btn.classList.remove('button__up--show');
      }
    });
  }, options);

  aboutSection.forEach(section => observer.observe(section));
});
