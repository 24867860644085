// node-modules
import { TweenLite } from 'gsap/TweenLite';

// constants
import { SELECTORS } from '../../scripts/constants';

// components
import { InputBlock } from './components/InputBlock';
// import { Captcha } from './components/Captcha'

// assets
import './Form.scss';

export const FORM_ELEMENTS = {
  input_block: '[data-role="input-block"]',
  input_block__input: '[data-role="input"]',
  input_block__label: '[data-role="label"]',
  input_block__error: '[data-role="error"]',
  captcha_block: '[data-role="captcha-block"]',
  content_block: '[data-role="content-block"]',
  message_block: '[data-role="message-block"]',
  message_loader: '[data-role="message-loader"]',
  message_success__mark: '[data-role="success-mark"]',
  message_error__mark: '[data-role="error-mark"]',
};

export const FORM_SELECTORS = {
  focused: 'is-focused',
  invalid: 'is-invalid',
  required: 'is-required',
};

export class Form {
  constructor(element) {
    this.element = element;

    const { sitekey = null, autosubmit } = this.element.dataset;

    this.autosubmit = autosubmit;

    this.message = this.element.querySelector(FORM_ELEMENTS.message_block);
    this.inputBlocks = Array.from(this.element.querySelectorAll(FORM_ELEMENTS.input_block))
      .map(inputBlock => new InputBlock(inputBlock, this));

    if (sitekey) {
      let captchaStarted = false;
      this.inputBlocks.forEach((input) => {
        input.on(['focus'], () => {
          if (!captchaStarted) {
            captchaStarted = true;
            this.captcha = new Captcha({
              element: this.element.querySelector(FORM_ELEMENTS.captcha_block),
              sitekey,
              formInstance: this,
            });
          }
        });
      });
    }

    this.init();
  }

  get isValid() {
    return this.element.checkValidity();
  }

  scrollToInvalid() {
    const target = this.element.querySelector(`.${FORM_SELECTORS.invalid}`);
    const distance = $(target).offset().top;
    TweenLite.to(window, 0.8, { scrollTo: { y: distance - 200, autoKill: false } });
  }

  validate() {
    if (this.isValid) {
      if (this.captcha) {
        this.captcha.execute();
      } else {
        this.validationSuccess();
      }
    } else {
      this.validationFail();
    }
  }

  validationSuccess() {
    this.element.submit();
  }

  validationFail() {

  }

  setupEvents() {
    this.element.addEventListener('submit', (e) => {
      e.preventDefault();
      this.validate();
    });
    if (this.autosubmit) {
      this.inputBlocks.forEach((inputBlock) => {
        inputBlock.on(['change'], () => this.element.submit());
      });
    }
  }

  init() {
    this.setupEvents();
    if (this.message) {
      setTimeout(() => {
        this.message.classList.add(SELECTORS.hidden);
      }, 3000);
    }
  }
}
