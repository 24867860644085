// node-modules
import * as Swiper from 'swiper/dist/js/swiper';


// assets
import 'swiper/dist/css/swiper.min.css';
import './swiperSlider.scss';

const sliderOptions = {
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 1000,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  keyboard: true,
};

export function sliderMoodboard(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    effect: 'fade',
    observer: true,
    observeParents: true,
    pagination: {
      el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
      clickable: true,
    },
    navigation: {
      nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
      prevEl: sliderContainer.querySelector('[data-role="slider-prev"]'),
    },
  });
}

export function sliderProduct(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    effect: 'slide',
    observer: true,
    observeParents: true,
    simulateTouch: false,
    allowTouchMove: false,
    // pagination: {
    //   el: sliderContainer.querySelector('[data-role="slider-pagination"]'),
    //   clickable: true,
    // },
    // navigation: {
    //   nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
    //   prevEl: sliderContainer.querySelector('[data-role="slider-prev"]'),
    // },
  });
}
