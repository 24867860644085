import scrollBarWidth from 'scrollbarwidth';
import { SCROLLBAR_MARGIN_SELECTORS, SCROLLBAR_PADDING_SELECTORS } from '@/scripts/constants';

export function toggleScrollbar(allow) {
  const scrollbar = scrollBarWidth();
  SCROLLBAR_MARGIN_SELECTORS.forEach((selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.style.marginRight = allow ? `${scrollbar}px` : '';
    }
  });
  SCROLLBAR_PADDING_SELECTORS.forEach((selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.style.paddingRight = allow ? `${scrollbar}px` : '';
    }
  });
}
